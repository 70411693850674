// Module imports
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// Database provider
import { DbContext } from '../DbProvider'
// Page imports
import Head from "../components/Head"
import Feed from "../components/Feed";
import Live from "../components/Live"
// Helper Functions
import { shuffleArray, getQuery } from "../components/HelperFunctions";
// Styles
import { Container, Col, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import LiveBar from "../components/LiveBar";

function Home() {
    const { loading, db } = useContext(DbContext);
    const [databaseStatus,setDatabaseStatus] = useState(true)
    const [feedcontent, setFeedContent] = useState([]);
    const [livecontent, setLiveContent] = useState([]);
    const { category } = useParams();
    
    useEffect(()=>{
        if (!loading) {
            try{
                var resultsfeed = db.exec(getQuery(category));
                const resultslive = db.exec(getQuery("latest"));
                if (category!='latest') {
                    shuffleArray(resultsfeed[0].values);
                }
                setFeedContent(resultsfeed[0]);
                setLiveContent(resultslive[0]);
                setDatabaseStatus(loading) 
            } catch(err) {
                console.log(err);
            } 
        }
    },[loading,category]);

    if (databaseStatus) return <pre>Loading...</pre>;
    else return (
        <Container className="p-3" style={{maxWidth:"1100px"}}>
            <Row className="p-0 m-0 g-0">
                <Col xs={12} md={8} className="pe-md-3">
                    <LiveBar/>
                    <Head data={feedcontent}/>
                    <Feed data={feedcontent}/>
                </Col>
                <Col className="d-none d-md-block">
                    <Live data={livecontent}/>
                </Col>
            </Row>
        </Container>
    );
}
export default Home;



