// Module imports
import React from "react";
import { Link } from "react-router-dom";
// Helper function
import { getColumn }from "./HelperFunctions"
// Style
import { Figure } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Head({data}) {
  const columns = getColumn(data.columns);
  const values = data.values[0];
    return (
        <div>
          <div className="card border-0 h-100 mb-3" style={{maxHeight:"23rem"}}>
              <Figure className="w-100 m-0" >
                <Figure.Image style={{maxHeight:"23rem", objectFit:"cover"}} className="w-100 rounded m-0" src={values[columns.imgurl]}/>
              </Figure>
              <div className="card-img-overlay h-100 d-flex flex-column justify-content-end" style={{background: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1), rgba(0,0,0,0.3))"}}>
                <Link to={"/article/"+values[columns.id]} style={ { textDecoration: 'none', fontWeight:"800", position: "absolute", color:"#ffffff", textTransform:"uppercase" } } className="fs-2">{values[columns.title]}</Link>
              </div>
          </div> 
        </div>
    );
}
export default Head;