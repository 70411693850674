import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// Helper function
import { getColumn }from "./HelperFunctions"

function Live({data}) {
    const columns = getColumn(data.columns);
    const values = data.values;
    return (
        <>
            <Row className="align-items-center">
                <Col xs={2} className="d-flex flex-row-reverse">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f8cc08" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                    </svg>
                </Col>
                <Col xs={10}>
                    <h2 style={{fontWeight:"800"}}>Just in</h2>
                </Col>
            </Row>
            {values.map((row) => 
            <Row key={row[columns.id]} className="mb-2 align-items-center">
                <Col xs={2} className="d-flex flex-row-reverse">
                    <div style={ { fontSize: "small", fontWeight:"500" } }>{new Date(row[columns.datetime]).toLocaleTimeString('en-GB', {hour: '2-digit',minute: '2-digit'})}</div>
                </Col>
                <Col xs={10} className="ps-0">
                    <Link to={"/article/"+row[columns.id]} style={ { textDecoration: 'none', fontWeight:"500", fontSize:"small", color:"#000000" } }>{row[columns.title]}</Link>
                </Col>
            </Row>
            )}
        </>

    ) 
    
}
export default Live;