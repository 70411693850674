// React modules
import React, { } from "react";
// Navbar
import Navigation from "./Navigation";
// Database
import { DbProvider } from "./DbProvider";
// Style
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <DbProvider>
      <div className="App">
        <Navigation/>
      </div>
    </DbProvider>
  );
}

export default App;

