// Module imports
import React from "react";
import { Link } from "react-router-dom";
// Helper function
import { getColumn, getTimeAgo }from "./HelperFunctions"
// Style
import { Col, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Feed({data}) {
    const blue = "#0ea5e9";
    const grey = "#404040";
    const columns = getColumn(data.columns);
    const values = data.values.slice(1,data.length);
    return (
        <div className="p-0">  
          {values.map((row) => 
            <Row key={row[columns.id]} className="article mb-4">
                <Col xs={3}>
                <div className="rounded h-100" alt="..." 
                    style = { { backgroundImage: "url(" + row[columns.imgurl] + ")", backgroundSize:"cover", backgroundPosition:"top"} }></div>
                </Col>
                <Col xs={8}>
                    <Row>
                        <Col xs="auto">
                            <div style={ { fontWeight:"700", fontSize:"small", color:blue } }>{row[columns.category]}</div>
                        </Col>
                        <Col xs="auto">
                            <div style={ { fontWeight: "400", fontSize: "small", color:grey } }>{row[columns.source]}</div>
                        </Col>
                    </Row>
                    <Link to={"/article/"+row[columns.id]} style={ { textDecoration: 'none', fontWeight:"800", color:grey } } className="fs-6">{row[columns.title]}</Link>
                    <p style={ { fontWeight:"500", color:grey } } className="d-none d-lg-block fs-6">{row[columns.paragraph]}</p>
                    <div style={ { fontSize: "small" } }>{getTimeAgo(new Date(row[columns.datetime]))}</div>
                </Col>
            </Row>
          )}
        </div>
    );
}
export default Feed;