import React, { useState, useEffect, useContext, Component } from "react";
import { useParams } from "react-router-dom";
import { DbContext } from '../DbProvider'
import { Container, Col, Row, Figure } from "react-bootstrap";
import {CommentsComponent} from "../components/comments"
// Helper function
import { fixParagraph, getColumn, getTimeAgo }from "../components/HelperFunctions"

function ArticlePage() {
    const { id } = useParams();
    const { loading, db } = useContext(DbContext);

    const [databaseStatus,setDatabaseStatus] = useState(true)
    const [articlecontent, setArticleContent] = useState([]);
    const [error, setError] = useState(null);

    useEffect(()=>{
        if (!loading) {
            try{
                const results = db.exec("SELECT * FROM articles WHERE id = '" + id + "'");
                setArticleContent(results[0]);
                setDatabaseStatus(loading) 
            } catch(err) {
                console.log(err);
            } 
        }
    },[loading]);

    if (databaseStatus) return <pre>Loading...</pre>;
    else {
        const columns = getColumn(articlecontent.columns);
        const row = articlecontent.values[0];     
     return (
        <Container>
            <Row>
                <Col xs={12} md={8}>
                    <Container key={row[columns.id]} fluid style={{maxWidth:"800px"}}>
                        <Figure className="w-100">
                            <Figure.Image src= {row[columns.imgurl]} style={{maxHeight:"23rem", objectFit:"cover"}} width="100%"  alt="Article image" rounded/>
                        </Figure>
                        <Row>
                            <Col xs="auto">
                                <div style={ { fontWeight:"700", fontSize:"medium", color:"#0ea5e9" } }>{row[columns.category]}</div>
                            </Col>
                            <Col xs="auto">
                                <div style={ { fontWeight: "400", fontSize: "medium", color:"#404040" } }>{row[columns.source]}</div>
                            </Col>
                            <Col xs="auto">
                                <div style={ { fontWeight:"400", fontSize:"medium" } }>{getTimeAgo(new Date(row[columns.datetime]))}</div>
                            </Col>
                        </Row>
                        <h1 className="my-3" style={ { fontWeight:"800"} }>{row[columns.title]}</h1>
                        <div className="fs-5" dangerouslySetInnerHTML={{ __html: fixParagraph(row[columns.summary]) }} />
                        <a className="fs-5" style={ { fontWeight:"500"} } target="_blank" rel="noreferrer noopener" href={row[columns.href]}>Read the full story here</a>
                        <div className="container my-5 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f8cc08" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="8"/>
                            </svg>
                        </div>
                    </Container>
                </Col>
                <Col className="d-none d-md-block">
                    <CommentsComponent commentid={row[columns.id]}/>
                    <div className="container my-5 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f8cc08" className="bi bi-circle-fill mx-1" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f8cc08" className="bi bi-circle-fill mx-1" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f8cc08" className="bi bi-circle-fill mx-1" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8"/>
                        </svg>
                    </div>
                </Col>
            </Row>
        </Container>
    )}
}
export default ArticlePage;

