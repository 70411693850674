// Function to get correct query for feed based on url parameters
export function getQuery(cat) {
    if (cat == "home" || cat==undefined) {
        var query = "SELECT * FROM articles WHERE category != 'Obituaries' ORDER BY dateTimeScraped DESC LIMIT 12";
    } else if (cat == "latest") {
        var query = "SELECT * FROM articles WHERE category != 'Obituaries' ORDER BY dateTimeScraped DESC LIMIT 12";
    } else if (cat=="sports") {
        var query = "SELECT * FROM articles WHERE category = 'Sports' ORDER BY dateTimeScraped DESC LIMIT 12";
    } else if (cat=="politics") {
        var query = "SELECT * FROM articles WHERE category = 'Politics' ORDER BY dateTimeScraped DESC LIMIT 12";
    } 
    return query;
}

// Returns index of column as name
export function getColumn(columns) {
    var column = [];
    // Find column indices
    column.id = columns.indexOf('id');
    column.source = columns.indexOf('source');
    column.title = columns.indexOf('title');
    column.category = columns.indexOf('category');
    column.author = columns.indexOf('author');
    column.href = columns.indexOf('href');
    column.paragraph = columns.indexOf('paragraph');
    column.datetime = columns.indexOf('datetimeScraped');
    column.imgurl = columns.indexOf('imgurl');
    column.summary = columns.indexOf('summary');
    return column
}

// Shuffels an array
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// Fixes bad paragraph text
export function fixParagraph(text) {
    text = text.replaceAll("< p >", "<p>");
    text = text.replaceAll("< / p >", "</p>");
    return text;
} 

// Returns time as "timeago"
export function getTimeAgo(date) {
    const MINUTE = 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const WEEK = DAY * 7;
    const MONTH = DAY * 30;
    const YEAR = DAY * 365;

    const secondsAgo = Math.round((Date.now() - Number(date)) / 1000);
  
    if (secondsAgo < MINUTE) {
      return secondsAgo + ` second${secondsAgo !== 1 ? "s" : ""} ago`;
    }
  
    let divisor;
    let unit = "";
  
    if (secondsAgo < HOUR) {
      [divisor, unit] = [MINUTE, "minute"];
    } else if (secondsAgo < DAY) {
      [divisor, unit] = [HOUR, "hour"];
    } else if (secondsAgo < WEEK) {
      [divisor, unit] = [DAY, "day"];
    } else if (secondsAgo < MONTH) {
      [divisor, unit] = [WEEK, "week"];
    } else if (secondsAgo < YEAR) {
      [divisor, unit] = [MONTH, "month"];
    } else {
      [divisor, unit] = [YEAR, "year"];
    }
  
    const count = Math.floor(secondsAgo / divisor);
    return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
}
